import { LegacyCard, FormLayout, Select, SelectOption, TextField } from "@shopify/polaris";
import { round } from "lodash"
import { useEffect, VFC } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ProductType } from "../../../../api-types"

export type ProductFormProductTypeValues = {
  productType?: ProductType
}

type StrictOption = Exclude<SelectOption, string>
const categoryOptions: StrictOption[] = Object
  .entries(ProductType)
  .map((v, _) => ({ label: v[1], value: v[1] }))

export const ProductFormProductTypeCard: VFC = () => {
  const { control } = useFormContext<ProductFormProductTypeValues>()

  return (
    <LegacyCard sectioned>
      <FormLayout>
        <Controller
          control={control}
          name={'productType'}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
            return (
              <Select
                label={'Product Type'}
                value={value}
                error={error?.message}
                onChange={onChange}
                onBlur={onBlur}
                options={categoryOptions}
                placeholder={'選択してください'}
              />
            )
          }}
        />
      </FormLayout>
    </LegacyCard>
  );
}
