import {
  LegacyCard,
  FormLayout
} from "@shopify/polaris"
import { useCallback, useState, useEffect, VFC } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { getYear, getMonth, addDays } from "date-fns"
import { FeatureGroup, ProductFeatureInput } from "../../../../api-types"
import { DateTextField } from "../../../ui"
import { FeatureGroupFields } from "./FeatureGroupFields"
import { DateTimeTextField } from "../../../ui/DateTimeTextField"

export type ProductFormOptionsValues = {
  preorderDate?: string
  comingSoon?: string
  productFeatures?: Array<ProductFeatureInput>
}

const today = new Date()
const yesterday = addDays(today, -1)

type Props = {
  featureGroups: FeatureGroup[]
  onChangePreOrderDate?: (value?: string) => void
  onChangeComingSoonDateTime?: (value?: string) => void
}

export const ProductFormOptionsCard: VFC<Props> = ({
  featureGroups,
  onChangePreOrderDate,
  onChangeComingSoonDateTime
}) => {
  const { t } = useTranslation()
  const {
    control,
    setValue
  } = useFormContext<ProductFormOptionsValues>()

  const handleOnClickClearPreOrderDate = useCallback(() => {
    setValue('preorderDate', void 0)
    onChangePreOrderDate?.(void 0)
  }, [])
  const handleOnClickClearComingSoon = useCallback(() => {
    setValue('comingSoon', void 0)
    onChangeComingSoonDateTime?.(void 0)
  }, [])

  return <>
    <LegacyCard title={t("Option")}>
      <LegacyCard.Section title={t('Features')}>
        {
          featureGroups.map((featureGroup) => {
            return (
              <LegacyCard.Subsection key={featureGroup.id}>
                <FeatureGroupFields featureGroup={featureGroup} control={control} />
              </LegacyCard.Subsection>
            );
          })
        }
      </LegacyCard.Section>
      <LegacyCard.Section title={t('Pre-Order Date')}>
        <Controller
          control={control}
          name="preorderDate"
          rules={{
            pattern: {
              value: /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
              message: t("Format should be 'yyyy-MM-dd'"),
            },
          }}
          render={({
            field: { value, onBlur, onChange },
            fieldState: { error },
          }) => {
            return (
              <DateTextField
                placeholder={'yyyy-MM-dd'}
                initialYear={getYear(today)}
                initialMonth={getMonth(today)}
                label={t('')}
                onChange={(value) => {
                  onChange(value)
                  onChangePreOrderDate?.(value)
                }}
                onBlur={onBlur}
                value={value}
                error={error?.message}
                disableDatesBefore={today}
                readOnly
                clearButton
                onClearButtonClick={handleOnClickClearPreOrderDate}
              />
            )
          }}
        />
      </LegacyCard.Section>
      <LegacyCard.Section title={t('Coming Soon Date Time')}>
        <Controller
          control={control}
          name='comingSoon'
          rules={{
            pattern: {
              value: /[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}/,
              message: t("Format should be 'yyyy-MM-dd HH:mm'"),
            },
          }}
          render={({
            field: { onBlur, onChange, value },
            fieldState: { error },
          }) => {
            return (
              <FormLayout>
                <FormLayout.Group>
                  <DateTimeTextField
                    placeholder={'yyyy-MM-dd HH:mm'}
                    initialYear={getYear(today)}
                    initialMonth={getMonth(today)}
                    label={''}
                    onChange={(value) => {
                      onChange(value)
                      onChangeComingSoonDateTime?.(value)
                    }}
                    onBlur={onBlur}
                    value={value}
                    error={error?.message}
                    disableDatesBefore={yesterday}
                    readOnly
                    clearButton
                    onClearButtonClick={handleOnClickClearComingSoon}
                  />
                </FormLayout.Group>
              </FormLayout>
            )
          }}
        />
      </LegacyCard.Section>
    </LegacyCard>
  </>;
}
