import { Checkbox, LegacyStack, TextField, Text } from "@shopify/polaris"
import { useCallback, VFC } from "react"
import { Control, useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Feature, FeatureGroup } from "../../../../api-types"
import { ProductFormOptionsValues } from "."

type FeatureGroupFieldsProps = {
  featureGroup: FeatureGroup
  control: Control<ProductFormOptionsValues, any>
}

export const FeatureGroupFields: VFC<FeatureGroupFieldsProps> = ({
  featureGroup,
  control
}) => {
  const { t } = useTranslation()
  const {
    fields,
    append,
    update,
    remove
  } = useFieldArray({ control: control, name: 'productFeatures' })

  const handleFeatureChecked = useCallback((checked: boolean, feature: Feature) => {
    if (checked) {
      append({ featureId: feature.id, otherText: '' })
    } else {
      const index = fields.findIndex(f => f.featureId === feature.id)
      remove(index)
    }
  }, [fields])
  const handleFeatureEditableChanged = useCallback((value: string, feature: Feature) => {
    const index = fields.findIndex(f => f.featureId === feature.id)
    update(index, { featureId: feature.id, otherText: value })
  }, [fields])

  return <>
    <LegacyStack distribution={'fill'}>
      <Text variant="bodyMd" as="span" tone="subdued">{t(featureGroup.name)}</Text>
    </LegacyStack>
    <LegacyStack distribution={'leading'} spacing={'loose'}>
      {featureGroup.features.map((feature) => {
        return (
          <LegacyStack.Item key={feature.id}>
            <Checkbox
              label={feature.name}
              id={feature.id}
              checked={fields.map(f => f.featureId).includes(feature.id)}
              onChange={(v) => handleFeatureChecked(v, feature)}
            />
          </LegacyStack.Item>
        );
      })}
    </LegacyStack>
    <LegacyStack distribution={'fill'}>
      {featureGroup.features.filter(feature => feature.editable).map((feature) => {
        const checked = fields.map(f => f.featureId).includes(feature.id)
        return (
          <LegacyStack.Item fill key={feature.id}>
            {
              (feature.editable && checked) && (
                <TextField
                  label='Other'
                  requiredIndicator
                  value={fields.find(f => f.featureId === feature.id)?.otherText ?? undefined}
                  onChange={(v) => handleFeatureEditableChanged(v, feature)}
                  autoComplete={'off'}
                />
              )
            }
          </LegacyStack.Item>
        );
      })}
    </LegacyStack>
  </>;
}
