import i18n, { Resource } from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import JA_JP from "../locales/ja/translation.json"
import EN_US from "../locales/en/translation.json"

const resources: Resource = {
  ja: { translation: JA_JP },
  en: { translation: EN_US },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
